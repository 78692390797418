// import { HomePage } from "pages";
import { lazy } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { PlaygroundRoutes, VoteRoutes } from './routes';
import { Girl2023 } from 'pages';
const Girl2023InfoProvider = lazy(() => import('store/girl-2023-context'));

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Navigate to='/vote/beautyCampaign2023' />} />
        <Route
          path='2022handsomeboy'
          element={<Navigate to='/vote/2022handsomeboy' replace={true} />}
        />
        <Route
          path='novastar'
          element={
            <Girl2023InfoProvider>
              <Girl2023 />
            </Girl2023InfoProvider>
          }
        />
        <Route path='vote/*' element={<VoteRoutes />} />
        <Route path='playground/*' element={<PlaygroundRoutes />} />
      </Routes>
    </>
  );
}

export default App;
